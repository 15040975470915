export const ActionTypes = {
    SET_USER: "SET_USER",
    UPDATE_USER: "UPDATE_USER",
    SET_COUNTRY: "SET_COUNTRY",
    SET_CARD: "SET_CARD",
    SET_GIFT: "SET_GIFT",
    SET_CARDPRODUCT: "SET_CARDPRODUCT",
    SET_GIFTPRODUCT: "SET_GIFTPRODUCT",
    SET_WISHLIST: "SET_WISHLIST",
    SET_CART: "SET_CART",
    SET_REMINDER: "SET_REMINDER",
    SET_ADDRESS: "SET_ADDRESS",
    SET_CONTACTS: "SET_CONTACTS"
};