import React, { useEffect } from "react";
import Hero from "../components/Home/Hero";
import Cards from "../components/Home/Cards";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { setUser } from "../redux/actions/userProfile";
import { useDispatch } from "react-redux";
import { setCountries } from "../redux/actions/Countries";
import { setCards } from "../redux/actions/Cards";
import { setGifts } from "../redux/actions/Gifts";
import { setCardProduct } from "../redux/actions/CardProduct";
import { setGiftProduct } from "../redux/actions/GiftProduct";
import { setWishlist } from "../redux/actions/Wishlist";
import { setCart } from "../redux/actions/Cart";
import { setReminder } from "../redux/actions/Reminder";
import { setContacts } from "../redux/actions/Contacts";
import { setAddress } from "../redux/actions/Address";

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();





  return (
    <>
      <Navigation />
      <Hero />
      <Cards />
      <Footer />
    </>
  );
}

export default Home;
