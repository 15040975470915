import { useDispatch } from "react-redux";

import { setUser } from "../redux/actions/userProfile";
import { setCountries } from "../redux/actions/Countries";
import { setCards } from "../redux/actions/Cards";
import { setGifts } from "../redux/actions/Gifts";
import { setCardProduct } from "../redux/actions/CardProduct";
import { setGiftProduct } from "../redux/actions/GiftProduct";
import { setWishlist } from "../redux/actions/Wishlist";
import { setCart } from "../redux/actions/Cart";
import { setReminder } from "../redux/actions/Reminder";
import { setContacts } from "../redux/actions/Contacts";
import { setAddress } from "../redux/actions/Address";

const UseApis = () => {
  const dispatch = useDispatch();

  const getUserWishlist = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("user"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${
        process.env.REACT_APP_API_URL
      }get-wishlist-by-id/${localStorage.getItem("id")}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setWishlist(result.wishlist));
      })
      .catch((error) => console.log("error", error));
  };

  const getUserData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("user"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}getUserById/${localStorage.getItem(
        "id"
      )}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setUser(result.data));
      })
      .catch((error) => console.log("error", error));
  };

  const getCountries = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("user"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}get-countries`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        dispatch(setCountries(result));
      })
      .catch((error) => console.log("error", error));
  };

  const getCardCats = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("user"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}card_cat`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        dispatch(setCards(result.data));
      })
      .catch((error) => console.log("error", error));
  };

  const getGiftCats = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("user"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}gift_cat`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        dispatch(setGifts(result.data));
      })
      .catch((error) => console.log("error", error));
  };

  const getGiftProduct = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("user"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}all/gifts`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        dispatch(setGiftProduct(result.data));
      })
      .catch((error) => console.log("error", error));
  };

  const getCardProduct = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("user"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}all/cards`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        dispatch(setCardProduct(result.data));
      })
      .catch((error) => console.log("error", error));
  };

  const getCartItem = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("user"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}cart`, requestOptions)
      .then((response) => response.json())

      .then((result) => {
        dispatch(setCart(result.cart));
      })
      .catch((error) => console.log("error", error));
  };

  const getAllReminders = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("user"));

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}reminder`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        dispatch(setReminder(result.reminders));
      })
      .catch((error) => console.log("error", error));
  };

  const getContacts = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("user"));
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}contacts`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        dispatch(setContacts(result.contacts));
      })
      .catch((error) => console.log("error", error));
  };

  const getAddresses = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("user"));
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}address`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        dispatch(setAddress(result.address));
      })
      .catch((error) => console.log("error", error));
  };

  return {
    getAddresses,
    getContacts,
    getAllReminders,
    getCardCats,
    getCardProduct,
    getCartItem,
    getCountries,
    getGiftCats,
    getGiftProduct,
    getUserData,
    getUserWishlist,
  };
};

export default UseApis;
