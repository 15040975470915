import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDpXH7_qiks7BrAc_ZWhiRGACM12uxJfMA",
  authDomain: "cardclub-409905.firebaseapp.com",
  projectId: "cardclub-409905",
  storageBucket: "cardclub-409905.appspot.com",
  messagingSenderId: "552239772583",
  appId: "1:552239772583:web:0a303e0140bd025ad8bc82",
  measurementId: "G-F4KKN4D0C1",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export { auth, googleProvider, facebookProvider };
