import React from 'react';
// import { data } from './utils/data';
import { DesignFrame } from '@lidojs/editor';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const EditorContent = () => {

    const { id } = useParams();
    let card = useSelector(state => {
        return state.cardProduct.cardinfo.filter(c => c.id == id)
    })

    card = card[0]
    console.log(card['image_path'])

    const data = [
        {
          layers: {
            ROOT: {
              type: { resolvedName: "RootLayer" },
              props: {
                boxSize: { width: 288, height: 556 },
                position: { x: 0, y: 0 },
                rotate: 0,
                color: "rgb(255, 255, 255)",
                image: {
                  url: process.env.REACT_APP_IMAGE_URL + card['image_path'],
                  thumb: process.env.REACT_APP_IMAGE_URL + card['image_path'],
                  boxSize: {
                    width: "100%",
                    height: "100%",
                  },
                },
              },
              locked: false,
              child: [],
              parent: null,
            },
          },
        },
        {
          layers: {
            ROOT: {
              type: { resolvedName: "RootLayer" },
              props: {
                boxSize: { width: 288, height: 556 },
                position: { x: 0, y: 0 },
                rotate: 0,
                color: "rgb(255, 255, 255)",
                image: null,
              },
              locked: false,
              child: [],
              parent: null,
            },
          },
        },
      ];

    return <DesignFrame data={data} />;
};

export default EditorContent;
